// Variables
@import 'variables';
$rtl:false;
// bulma
@import '~bulma';
/*--------plugins-------*/
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~hover.css/scss/hover.scss';
@import '~bulma-extensions/bulma-checkradio/src/sass/index.sass';
@import '~sweetalert2/dist/sweetalert2.min.css';
body.landing{
	background-color: rgb(106,73,205);
	min-height: 100vh;
	position: relative;
	background-image: url('/front/img/bg.png');
	&:before{
		content:'';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0; 
		background-image: url('/front/img/bg1.png');
		background-size: cover;
		background-position: bottom;
		min-height: 100vh;
		z-index: 1
	}
	

}
.button.is-gradient{
	border-radius: 25px;
	background: linear-gradient(270deg, #4d00ba 0%, #5008bc 8.66%, #5a21c3 22.8%, #6a49cd 40.65%, #8180dc 61.51%, #9dc5ef 84.62%, #b2f8fd 100%);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	color: #fff;
}
.hero-body .main-content{
	position: relative;
	z-index: 2;
}
body.inner-pages{
	&:before{
		content:'';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0; 
		background-image: url('/front/img/bg.png');
		background-size: cover;
		background-position: bottom;
		height: calc(128px + 20rem)
	}
	&:after{
		content:'';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0; 
		background-image: url('/front/img/bg2.png');
		background-size: 102%;
		background-position: bottom right;
		height: calc(128px + 20rem)
	}
	.navbar-item img{
		max-height: 3.75rem;
	}
	.main-content{
		margin-top: -60px;
	}
}
.apps-link {
	height: 80px;
	display: inline-block;
	img {
		height: 100%;
	}

}
.mobile-image{
	max-height: 62vh;
}
.navbar-item img{
	max-height: 6.75rem;
}
a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active{
	background-color: transparent;
	color: #fff;
}
img.image-contain{
	height: 100%;
	width: 100%;
	object-fit:contain;
}
.navbar-burger{
	color: $white;
}
.mx-auto{
	margin-left: auto;
	margin-right: auto;
}
.navbar-burger span{
	height: 2px;
}
@media screen and (max-width: 1023px){
	.navbar-menu{
		&.is-active {
			display: block;
			position: absolute;
			right: 0;
			left: 0;
			background-color: #8fa1e5;
			box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
			padding: 0.5rem 0;
			border-top: 1px solid #fff;
			.navbar-item{
				padding: 0.5rem 1.75rem;
				font-size: 1.5rem;
				color: $primary;
				.button{
					padding: 0;
					font-size: inherit;
					display: block;
					text-align: left;
					background: transparent;
					color: $primary;
					.icon{
						display: none;
					}
					
				}
			}
		}
	}
}
@media screen and (max-width: 768px){

	body{
		&.landing , &.inner-pages{
			background: linear-gradient(45deg, #4d00ba 0%, #5008bc 8.66%, #5a21c3 22.8%, #6a49cd 40.65%, #8180dc 61.51%, #9dc5ef 84.62%, #b2f8fd 100%);
			min-height: 100vh;
			position: relative;
		}
		&:before{
			content:unset!important;
		}
		&:after{
			content:unset!important;
		}


	}
}


